<template>
  <v-card
    class="ma-4"
    color="transparent"
    variant="flat"
    @click="videoBus.emit(video)"
  >
    <v-card-item class="text-center">
      <v-icon :icon="mdiPlayCircleOutline" color="white" size="100" />

      <div
        v-if="title"
        class="d-none d-sm-flex text-h5 text-md-h4 text-white mt-2"
      >
        {{ title }}
      </div>
    </v-card-item>
  </v-card>
</template>

<script setup>
import { useEventBus } from '@vueuse/core'
import { mdiPlayCircleOutline } from '@mdi/js'

defineProps({
  title: {
    default: 'Play the Video',
    type: String
  },
  video: {
    required: true,
    type: Object
  }
})

const videoBus = useEventBus('video')
</script>
